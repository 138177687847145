import {
  type IdDocumentsType,
  KYC_IDS,
  useBuybackConfig,
} from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { required } from '@ds/components/Form'
import type { FileError } from '@ds/components/InputFileUpload/InputFileUpload.type'

import type {
  FormValues,
  InputFile,
} from '~/scopes/buyback/components/FormGenerator/FormGenerator.vue'
import {
  ACCEPTED_FILE_EXTENSIONS,
  ID_CARD_ERROR_TYPES,
  MAX_FILE_SIZE,
  MIN_FILE_SIZE,
  formatFileSize,
} from '~/scopes/dashboard/my-sales/components/Modals/KycDetails/identity/Identity.utils'

import translations from './useGenerateIdCardForm.translations'

export const useGenerateIdCardForm = (
  idDocumentType: IdDocumentsType,
): Array<InputFile> => {
  const i18n = useI18n()
  const { kyc } = useBuybackConfig()
  const identity = kyc[KYC_IDS.IDENTITY].list.find(
    ({ type }) => type === idDocumentType,
  )
  const { openErrorToast } = useTheToast()

  function validateIdCardInput(field: unknown, form: FormValues) {
    return required(i18n(translations.required))(field, form, {})
  }

  const inputFileErrorsLabel = {
    [ID_CARD_ERROR_TYPES.MIN_FILE_SIZE_NOT_EXCEEDED]: i18n(
      translations.tooSmallError,
      {
        size: formatFileSize(MIN_FILE_SIZE),
      },
    ),
    [ID_CARD_ERROR_TYPES.MAX_FILE_SIZE_EXCEEDED]: i18n(
      translations.tooLargeError,
    ),
    [ID_CARD_ERROR_TYPES.WRONG_FILE_EXTENSION]: i18n(
      translations.wrongFileTypeError,
    ),
  }

  if (identity?.isOneSide) {
    return [
      {
        id: 'idCardBothSidesFile',
        cols: 6,
        type: 'file',
        description: i18n(translations.fileFormatHint, {
          size: formatFileSize(MAX_FILE_SIZE),
        }),
        labelEmpty: i18n(translations.myFile),
        acceptedExtensions: ACCEPTED_FILE_EXTENSIONS,
        label: '',
        extraCss: ['mt-32'],
        handleErrors: (errors: FileError[]) =>
          openErrorToast({
            title: i18n(translations.errorToastTitle),
            content: errors.reduce(
              (_, error) => inputFileErrorsLabel[error.type],
              '',
            ),
          }),
        validation: [(field, form) => validateIdCardInput(field, form)],
      },
    ]
  }

  return [
    {
      id: 'idCardFrontFile',
      cols: 6,
      type: 'file',
      description: i18n(translations.fileFormatHint, {
        size: formatFileSize(MAX_FILE_SIZE),
      }),
      labelEmpty: i18n(translations.front),
      acceptedExtensions: ACCEPTED_FILE_EXTENSIONS,
      label: '',
      handleErrors: (errors: FileError[]) =>
        openErrorToast({
          title: i18n(translations.errorToastTitle),
          content: errors.reduce(
            (_, error) => inputFileErrorsLabel[error.type],
            '',
          ),
        }),
      validation: [(field, form) => validateIdCardInput(field, form)],
    },
    {
      id: 'idCardBackFile',
      cols: 6,
      type: 'file',
      description: i18n(translations.fileFormatHint, {
        size: formatFileSize(MAX_FILE_SIZE),
      }),
      labelEmpty: i18n(translations.back),
      acceptedExtensions: ACCEPTED_FILE_EXTENSIONS,
      label: '',
      handleErrors: (errors: FileError[]) =>
        openErrorToast({
          title: i18n(translations.errorToastTitle),
          content: errors.reduce(
            (_, error) => inputFileErrorsLabel[error.type],
            '',
          ),
        }),
      validation: [(field, form) => validateIdCardInput(field, form)],
    },
  ]
}
