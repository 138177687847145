<template>
  <div>
    <p class="text-static-default-low body-1 mb-3 mt-4">
      {{ i18n(translations.title) }}
    </p>

    <p class="text-static-default-low body-1 mt-24">
      {{ i18n(translations.subTitle) }}
    </p>

    <h3 class="body-1-bold my-24">
      {{ label }}
    </h3>

    <div v-for="idDoc in ids" :key="idDoc" class="mt-32">
      <FormGenerator
        v-if="idDoc === id"
        :extra-css="['!gap-28']"
        :form-config="formsConfig[idDoc]"
        :form-id="formId"
        :has-submit-button="false"
        @submit="handleSubmit"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from 'vue'

import {
  ID_DOCUMENTS,
  type IdDocumentsType,
} from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import FormGenerator, {
  type FormValues,
} from '~/scopes/buyback/components/FormGenerator/FormGenerator.vue'
import { useGenerateIdCardForm } from '~/scopes/buyback/config/useGenerateIdCardForm'

import translations from './Identity.translations'

const i18n = useI18n()
const props = defineProps<{
  formId: string
  label: string
  id: IdDocumentsType
  ids: IdDocumentsType[]
}>()

const emit = defineEmits<{
  submit: [values: FormValues]
}>()

const formsConfig = reactive({
  [ID_DOCUMENTS.ID_CARD]: props.ids.includes(ID_DOCUMENTS.ID_CARD)
    ? useGenerateIdCardForm(ID_DOCUMENTS.ID_CARD)
    : [],
  [ID_DOCUMENTS.PASSPORT]: props.ids.includes(ID_DOCUMENTS.PASSPORT)
    ? useGenerateIdCardForm(ID_DOCUMENTS.PASSPORT)
    : [],
  [ID_DOCUMENTS.DRIVING_LICENCE]: props.ids.includes(
    ID_DOCUMENTS.DRIVING_LICENCE,
  )
    ? useGenerateIdCardForm(ID_DOCUMENTS.DRIVING_LICENCE)
    : [],
})

function handleSubmit(values: FormValues) {
  const data = {}

  if (values.idCardBothSidesFile) {
    Object.assign(data, {
      idCardBothSidesFile: (values.idCardBothSidesFile as File[])[0],
    })
  } else {
    Object.assign(data, {
      idCardFrontFile: (values.idCardFrontFile as File[])[0],
      idCardBackFile: (values.idCardBackFile as File[])[0],
    })
  }

  emit('submit', data)
}
</script>
