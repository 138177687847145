<template>
  <div>
    <div v-if="showForm">
      {{ i18n(translations.description) }}
      <FormGenerator
        class="mt-32"
        :form-config="formConfig"
        :form-id="formId"
        :has-submit-button="false"
        @submit="handleSubmit"
      />
    </div>
    <div v-else>
      {{ i18n(translations.previewDescription) }}
      <RevList class="mt-32" :hasExternalBorders="false">
        <RevListItemInteractive
          v-for="[label, description] in validApiValues"
          :key="label"
        >
          <template #label>
            {{ i18n(translations[label as keyof typeof translations]) }}
          </template>
          <template #description>
            {{ description }}
          </template>
        </RevListItemInteractive>
      </RevList>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { BankDetailsResponse } from '@backmarket/http-api/src/api-specs-buyback/customer'
import {
  BANKING_DETAILS,
  type BankingDetailsType,
  KYC_IDS,
  useBuybackConfig,
} from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'

import FormGenerator, {
  type FormValues,
  type Input,
} from '~/scopes/buyback/components/FormGenerator/FormGenerator.vue'
import { generateAchForm } from '~/scopes/buyback/config/generators/generateAchForm'
import { generateBacsForm } from '~/scopes/buyback/config/generators/generateBacsForm'
import { generateIbanForm } from '~/scopes/buyback/config/generators/generateIbanForm'

import translations from './BankDetails.translations'

const BANK_TYPES: Record<BankingDetailsType, Input[]> = {
  [BANKING_DETAILS.iban]: generateIbanForm(),
  [BANKING_DETAILS.bacs]: generateBacsForm().map((item) => ({
    ...item,
    cols: 6,
  })),
  [BANKING_DETAILS.ach]: generateAchForm(),
}

const props = defineProps<{
  formId: string
  bankingValues: BankDetailsResponse
  showForm: boolean
}>()
const emit = defineEmits<{
  submit: [values: FormValues]
}>()
const i18n = useI18n()
const marketConfig = useBuybackConfig()
const formConfig: Array<Input> = [
  ...BANK_TYPES[marketConfig?.kyc[KYC_IDS.BANK_DETAILS].type],
]

const validApiValues = computed(() => {
  const { iban, bic, routingNumber, accountNumber, sortCode } =
    props.bankingValues

  return Object.entries({
    iban,
    bic,
    routingNumber,
    accountNumber,
    sortCode,
  }).filter(([, val]) => Boolean(val))
})

function handleSubmit(values: FormValues) {
  emit('submit', values)
}
</script>
