<template>
  <div>
    <p class="text-static-default-low body-1 mt-12">
      {{ i18n(translations.IdentityChoiceFirstText) }}
    </p>
    <p class="text-static-default-low body-1 mt-12">
      {{ i18n(translations.IdentityChoiceSecondText) }}
    </p>
    <p class="body-1-bold mt-12">
      {{ i18n(translations.IdentityChoiceQuestion) }}
    </p>

    <div class="mt-32">
      <RevRadio
        v-for="option in radioOptions"
        :id="option.id"
        :key="option.id"
        v-model="radioId as string"
        class="mt-16"
        :value="option.id"
        variant="full"
      >
        <template #label>{{ option.label }}</template>
      </RevRadio>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type IdDocumentsType } from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevRadio } from '@ds/components/Radio'

import translations from './Identity.translations'

defineProps<{
  radioOptions: {
    id: IdDocumentsType
    label: string
    isOneSide: boolean
  }[]
}>()
const radioId = defineModel<IdDocumentsType>('id')

const i18n = useI18n()
</script>
