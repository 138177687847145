<template>
  <RevDrawer
    :back-button-label="i18n(modalBuybackTranslations.backButtonLabel)"
    :close-button-label="i18n(modalBuybackTranslations.close)"
    :name="name"
    :title="i18n(translations.modalTitle)"
    @close="emit('close')"
  >
    <template #body v-if="data">
      <div>
        <h3 class="heading-3 mt-4">{{ i18n(translations.title) }}</h3>
        <p class="text-static-default-low body-1 mt-12">
          {{ i18n(translations.noMatch) }}
        </p>
        <p class="text-static-default-low body-1 mt-12">
          {{ i18n(translations.description) }}
        </p>

        <div class="mt-40 flex justify-between">
          <div>
            <span class="text-static-default-hi body-1-bold">
              {{ data.formattedCounterOfferPrice }}
            </span>
            <span class="caption-striked ml-1 text-[#75777c] line-through">{{
              data.formattedPrice
            }}</span>
          </div>
          <RevTag
            class="leading-[1.5rem]"
            :label="
              i18n(translations.tag, {
                count: data.remainingDays,
              })
            "
            variant="secondary"
          />
        </div>

        <div v-if="hasImages" data-test="reasons-carousel">
          <RevCarousel
            :alternative-button-label="i18n(translations.alternativeController)"
            :alternative-controller="i18n(translations.alternativeController)"
            :alternative-current="i18n(translations.alternativeCurrent)"
            :alternative-next="i18n(translations.alternativeNext)"
            :alternative-previous="i18n(translations.alternativePrevious)"
            class="mt-16"
            :controller-images="data.imagesList"
            controller-position="bottom"
            controller-variant="dots"
            current-index-id="counter-offer-carousel"
            :display-navigation="true"
          >
            <RevIllustration
              v-for="image in data.imagesList"
              :key="image.src"
              :alt="`Counter offer image ${id} reason`"
              class="rounded-xs mt-8 w-auto"
              :height="313"
              :src="image.src"
              :width="366"
            />
          </RevCarousel>
        </div>
      </div>
      <RevList v-if="hasPdf" class="mt-12 px-6" :has-dividers="false">
        <RevListItemInteractive
          v-for="{ id: docId, src, displayName } in data.documentsList"
          :key="docId"
        >
          <template #label>
            <RevLink
              class="block truncate text-left [direction:rtl]"
              :to="src"
              :underlined="false"
            >
              {{ displayName }}
            </RevLink>
          </template>
        </RevListItemInteractive>
      </RevList>

      <h3 class="body-1-bold mt-12">
        {{ i18n(translations.reason) }}
      </h3>
      <ul class="list-none divide-y focus:outline-none">
        <li
          v-for="{ id: reasonId, label } in data.reasonsList"
          :key="reasonId"
          class="text-static-default-low body-1 relative list-none py-20 tap-highlight-transparent only:border-0 focus:outline-none"
        >
          {{ label }}
        </li>
      </ul>

      <RevInfoBlock
        v-if="hasComment"
        class="mt-24"
        :content="data.comment"
        :icon="IconComment"
        :title="i18n(translations.commentTitle)"
        variant="default"
      />

      <div class="mb-16 mt-32">
        <h3 class="text-static-default-hi heading-3">
          {{ i18n(translations.nextTitle) }}
        </h3>
        <p class="mt-8">{{ i18n(translations.next1) }}</p>
        <p class="my-8">{{ i18n(translations.next2) }}</p>
        <RevLink
          class="body-2-bold"
          :to="buybackConfig.tradeinFaq?.counterOffer"
        >
          {{ i18n(translations.getHelp) }}
        </RevLink>
      </div>
    </template>
    <template #footer>
      <div class="flex gap-8">
        <RevButton
          full-width="always"
          variant="secondary"
          @click="emit('reject')"
        >
          {{ i18n(translations.rejectButton) }}
        </RevButton>
        <RevButton
          full-width="always"
          :loading="loading"
          variant="primary"
          @click="handleAccept"
        >
          {{ i18n(translations.acceptButton) }}
        </RevButton>
      </div>
    </template>
  </RevDrawer>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { buybackCustomerAPI } from '@backmarket/http-api'
import { useBuybackConfig } from '@backmarket/nuxt-layer-buyback/composables/config/useBuybackConfig'
import modalBuybackTranslations from '@backmarket/nuxt-layer-buyback/utils/Modal.translations'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevButton } from '@ds/components/Button'
import { RevCarousel } from '@ds/components/Carousel'
import { RevDrawer } from '@ds/components/Drawer'
import { RevIllustration } from '@ds/components/Illustration'
import { RevInfoBlock } from '@ds/components/InfoBlock'
import { RevLink } from '@ds/components/Link'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { RevTag } from '@ds/components/Tag'
import { IconComment } from '@ds/icons/IconComment'

import { counterOfferAdapter } from '~/scopes/buyback/adapters/counterOfferAdapter'

import translations from './CounterOfferModal.translations'

const emit = defineEmits(['is-loaded', 'reload-sales', 'reject', 'close'])
const props = defineProps<{
  name: string
  id: number
}>()

const loading = ref(false)

const buybackConfig = useBuybackConfig()
const { openErrorToast, openSuccessToast } = useTheToast()
const i18n = useI18n()

const { data, pending } = useHttpFetch(buybackCustomerAPI.getCounterOffer, {
  pathParams: {
    id: props.id,
  },
  transform: counterOfferAdapter,
})

const hasImages = computed(
  () => data.value?.imagesList.length && data.value.imagesList.length > 0,
)
const hasPdf = computed(
  () => data.value?.documentsList.length && data.value.documentsList.length > 0,
)
const hasComment = computed(() => data.value?.comment)

async function handleAccept() {
  try {
    loading.value = true
    await $httpFetch(buybackCustomerAPI.putCounterOffer, {
      pathParams: {
        id: props.id,
      },
      body: {
        counterOfferStatus: 'Accepted',
      },
    })
    openSuccessToast({
      title: i18n(translations.toastCounterOfferAcceptTitle),
      content: i18n(translations.toastCounterOfferAcceptContent),
    })
    emit('reload-sales')
    emit('close')
  } catch (err) {
    openErrorToast({
      title: i18n(translations.toastCounterOfferErrorTitle),
      content: i18n(translations.toastCounterOfferErrorContent),
    })
  } finally {
    loading.value = false
  }
}

watch(pending, (newVal) => {
  if (newVal === false) {
    emit('is-loaded')
  }
})
</script>
